<template>
  <span>
    <div class="persona">
      <div class="persona-image-area"><img :src="image" class="image"></div>
      <div class="persona-details">
          <div class="primary-text" v-if="primary">{{primary}}</div>
          <div class="secondary-text" v-if="secondary">{{secondary}}</div>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  props: ["user_uuid","image","primary", "secondary","optional","tertiary"],
  data() {
    return {      
      
    };
  },
};
</script>
<style>
</style>
