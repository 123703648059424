<template>
  <span>
    <div class="attachment">
      <div class="icon"><i class="fal fa-file"></i></div>
      <div class="information">
        <div class="name">{{ info.name }}</div>
        <div class="filesize">
          <a v-if="downloadAsBlob == false" href="#" @click="getDownloadUrl" v-show="!loading" v-b-tooltip.hover :title='$t("READ.DOWNLOAD")'>
             <i class="fal fa-download"></i> {{
            $t("READ.DOWNLOAD")
          }}</a>
          
          <a target="_blank" :href="getAttachmentUrl" style="display: none;" ref="downloadFile">Download</a>

          <a v-if="downloadAsBlob == true" href="#" @click="download" v-show="!loading" v-b-tooltip.hover :title='$t("READ.DOWNLOAD")'>
            <i class="fal fa-download"></i> {{ $t("READ.DOWNLOAD")}}</a>

        </div>
      </div>
    </div>
  </span>
</template>
<script>
import * as blobUtil from 'blob-util';
export default {
  props: ["item","index","message_uuid"],
  data() {
    return {
      loading: false,
      info: this.item,
      download_uuid: '',
      addinClient: ''
    };
  },
  methods: {
    async getDownloadUrl() {
      let result = await this.$http.post(
        this.user.hostname + "/message/download/generate",
        {
          message_uuid: this.message_uuid,
          attachment_index: this.index
        }
      );
      let openUrl = this.user.hostname + "/message/download/" + result.data.download_uuid;
      this.$emit('openBrowserWindow',openUrl);
/*      let resultOpenBrowserWindow = this.$Office.context.ui.openBrowserWindow(openUrl);
      console.log(resultOpenBrowserWindow);
  */
  /*this.download_uuid = result.data.download_uuid;
      let self = this;
      setTimeout(function () {
        self.$refs.downloadFile.click();
      },100);*/
    },
    download() {
      let blob = blobUtil.base64StringToBlob(this.info.content, 'application/octet-stream');
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, this.info.name);
      } else {    
        this.downloadBlob(blob,this.info.name);
      }
    },
    downloadBlob(blob, name) {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = name;
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent('click', { 
          bubbles: true, 
          cancelable: true, 
          view: window 
        })
      );
      document.body.removeChild(link);
    }
  },
  computed: {    
    getAttachmentUrl() {
      return this.user.hostname + "/message/download/" + this.download_uuid;
    },
    downloadAsBlob() {
      return false;
      /*if(this.addinClient == 'OutlookWebApp')
      {
        return false;
      }else{
        return true;
      }*/
    }
  },
  mounted() {    
  }
};
</script>
<style>
</style>